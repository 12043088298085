export const terapi = [
  { path: '/terapi/angst-og-fobier/', text: 'Angst & Fobier' },
  { path: '/terapi/depression/', text: 'Depression' },
  { path: '/terapi/ocd/', text: 'OCD' },
  { path: '/terapi/stress/', text: 'Stress' },
  {
    path: '/terapi/body-dysmorphic-disorder/',
    text: 'Body dysmorphic disorder',
  },
  { path: '/terapi/perfektionisme/', text: 'Perfektionisme' },
  {
    path: '/terapi/udtrapning-af-psykofarmaka/',
    text: 'Udtrapning af psykofarmaka',
  },
  {
    path: '/terapi/coaching/',
    text: 'Coaching',
  },
];

export const angst = [
  {
    path: '/angst/eksamens-og-praestationsangst/',
    text: 'Eksamens og præstationsangst',
  },
  { path: '/angst/generaliseret-angst/', text: 'Generaliseret angst' },
  { path: '/angst/panikangst/', text: 'Panikangst' },
  { path: '/angst/separationsangst/', text: 'Separationsangst' },
  {
    path: '/angst/sygdomsangst-helbredsangst/',
    text: 'Sygdomsangst - helbredsangst',
  },
  { path: '/angst/angstens-symptomer/', text: 'Angstens symptomer' },
  { path: '/angst/angst-og-lavt-selvvaerd/', text: 'Angst og lavt selvværd' },
  { path: '/angst/agorafobi/', text: 'Agorafobi' },
  { path: '/angst/fobier/', text: 'Fobier' },
  { path: '/angst/socialfobi/', text: 'Socialfobi' },
];

export const metoder = [
  { path: '/metoder/metakognitiv-terapi/', text: 'Metakognitiv terapi' },
  { path: '/metoder/meditation/', text: 'Meditation' },
  { path: '/metoder/kognitiv-adfaerdsterapi/', text: 'Kognitiv adfærdsterapi' },
  { path: '/metoder/foraeldreraadgivning/', text: 'Forældrerådgivning' },
  { path: '/metoder/onlinesessioner/', text: 'Onlinesessioner' },
  { path: '/metoder/hvordan-foregaar-det/', text: 'Hvordan foregår det' },
  { path: '/metoder/valg-af-psykolog/', text: 'Valg af psykolog' },
];

export const info = [
  { path: '/info/priser-og-vilkaar/', text: 'Priser og vilkår' },
  { path: '/info/tilskudsordninger/', text: 'Tilskudsordninger' },
  { path: '/info/tider/', text: 'Tider' },
  { path: '/info/afbudsregler/', text: 'Afbudsregler' },
  { path: '/info/aldersgruppe/', text: 'Aldersgruppe' },
  { path: '/info/persondatapolitik/', text: 'Persondatapolitik' },
];

export const hæfter = [
  {
    path: '/haefter/eksamensangst-og-metakognitiv-terapi',
    text: 'Eksamensangst og metakognitiv terapi',
  },
  {
    path: '/haefter/ocd-og-metakognitiv-terapi/',
    text: 'OCD og metakognitiv terapi',
  },
  {
    path: '/haefter/angst-og-metakognitiv-terapi/',
    text: 'Angst og metakognitiv terapi',
  },
  {
    path: '/haefter/depression-og-metakognitiv-terapi/',
    text: 'Depression og metakognitiv terapi',
  },
  {
    path: '/haefter/udtrapning-af-psykofarmaka-og-metakognitiv-terapi/',
    text: 'Udtrapning af psykofarmaka og metakognitiv terapi',
  },
  { path: '/haefter/panikangst/', text: 'Panikangst' },
  {
    path: '/haefter/body-dysmorphic-disorder/',
    text: 'Body Dysmorphic Disorder',
  },
  {
    path: '/haefter/et-par-ord-om-at-gaa-til-psykolog-for-boern/',
    text: 'Et par ord om at gå til psykolog - for børn',
  },
  {
    path: '/haefter/diasshow-til-børn-om-metakognitiv-terapi/',
    text: 'Diasshow til børn om metakognitiv terapi',
  },
  {
    path: '/haefter/diasshow-til-unge-med-stress/',
    text: 'Diasshow til unge med stress',
  },
];
